html, body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  color: #5DADE2;
  background-color: #282c34;
  font: 1.2em "Fira Sans", sans-serif;
  font-size: 14px;
  align-items: center;
  justify-content: center;
}

input::selection {
  color: white;
  background: #117A65;
}

a {
  color: #5DADE2;
  text-decoration: none;
}

h2 {
  margin: 0;
  padding: 0;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 2px;
}

.left {
  margin-left: 0px;
  justify-content: start;
}

.right {
  margin-right: 0px;
  justify-content: end;
}

.center {
  justify-content: center;
}

.number__label {
  width: 128px;
}

.number__input {

  font-size: 18px;
  text-align: right;
  border-radius: 4px;
  border-color: #1B4F72;
  border-style: solid;
  color: #5DADE2;
  background-color: #282c34;
  height: 24px;
  width: 148px;
}

.number__input:focus {
  outline: none !important;
  border-color: #5DADE2;
}

.number__operation {
  margin-left: 4px;
  text-align: center;
  border-radius: 4px;
  border-color: #1B4F72;
  border-style: solid;
  color: #5DADE2;
  background-color: #282c34;
  width: 26px;
  height: 26px;
}

.number__operation:hover {
  border-color: #5DADE2;
}

.operation {
  margin-top: 36px;
  text-align: center;
  color: #5DADE2;
  background-color: #282c34;
  width: 64px;
}

.operation__button {
  margin-top: 39px;
  margin-left: 16px;
  text-align: center;
  border-radius: 4px;
  border-color: #1B4F72;
  border-style: solid;
  color: #5DADE2;
  background-color: #282c34;

  height: 26px;
}

.operation__button:hover {
  border-color: #5DADE2;
}

.notation {
  margin-top: 36px;
  text-align: center;
  color: #5DADE2;
  width: 64px;
}

.number__bits {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.bit-button {
  margin: 2px;
  font-size: 12px;
  text-align: center;
  border-radius: 4px;
  border-color: #1B4F72;
  border-style: solid;
  width: 26px;
  height: 26px;
}

.bit-on {
  color: white;
  background-color: #117A65;
}

.bit-off {
  color: #5DADE2;
  background-color: #282c34;
}

.bit-off:hover {
  border-color: #5DADE2;
}

.bit-on:hover {
  border-color: #5DADE2;
}

.bit-space {
  width: 0px;
}

